<template>
  <v-card elevation="0" class="cardProduct">
    <favoriteBtn
      v-if="showFavoriteBtn"
      class="favoriteBtn"
      :defaultStatus="isFavorite"
      :store-id="storeId"
      :provider-id="providerId"
      :product-id="productId"
    ></favoriteBtn>

    <div v-if="displayCardMode === 'normal'">
      <v-checkbox
        v-if="batchConfig && tokenRole == 'provider'"
        @change="toggleSelected"
        v-model="selected"
        class="list-item-checkbox"
      ></v-checkbox>

      <router-link :to="toProductDetail">
        <v-img
          v-if="allSizePhotoList[0]"
          class="cardProduct--image"
          :lazy-src="allSizePhotoList[0].middlePhotoUrl"
          :src="allSizePhotoList[0].originPhotoUrl"
          aspect-ratio="1"
          contain
        >       
          <template v-slot:placeholder>
            <v-row
              v-if="!allSizePhotoList[0].originPhotoUrl"
              eagle-list="photo-placeholder"
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-icon size="22">{{ placeholderIcon }}</v-icon>
            </v-row>
          </template>
        </v-img>
        <v-img
          v-else
          class="cardProduct--image"
          src="~@/assets/product-dafault.jpeg"
          lazy-src="~@/assets/product-dafault.jpeg"
          aspect-ratio="1"
        >
        </v-img>
        <p class="cardProduct--title">{{ name }}</p>
        <p class="cardProduct--price">{{ computedPrice }}</p>
      </router-link>

      <v-row v-if="showAction">
        <v-col cols="12" lg="6" md="6" sm="6">
          <v-switch
            dense
            color="green"
            inset
            :label="actionText"
            @change="action"
            v-model="status"
          ></v-switch>
        </v-col>
        <v-col
          cols="12" lg="6" md="6" sm="6"
          class="d-flex justify-center justify-md-end px-0 px-md-6"
        >
          <v-btn color="primary" class="mr-3" :to="shareProductRoute">
            分享連結
          </v-btn>
          <v-btn color="primary" :to="cloneProductRoute">
            複製商品
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <div v-if="displayCardMode === 'list'">
      <!-- 電腦版 -->
      <div v-if="!$helper.isMobile()" class="d-flex justify-space-between cardProduct--list-container pa-2">
        <div class="flex-grow-0 flex-shrink-0" style="width: 40px">
          <v-checkbox
            v-if="batchConfig && tokenRole == 'provider'"
            @change="toggleSelected"
            v-model="selected"
            class="list-item-checkbox"
          ></v-checkbox>
        </div>
        <div class="flex-grow-1 flex-shrink-1 pl-4 pt-2">
          <p class="cardProduct--title">{{ name }}</p>
          <br>
          <div class="d-flex justify-space-between pr-4">
            <div>
              <v-switch
                dense
                color="green"
                hide-details
                inset
                :label="actionText"
                @change="action"
                v-model="status"
              ></v-switch>
            </div>

            <div class="pt-1">
              <v-btn small color="primary" :to="cloneProductRoute">
                複製商品
              </v-btn>
            </div>
          </div>
        </div>
        <div class="flex-grow-0 flex-shrink-0" style="width: 110px">
          <router-link :to="toProductDetail">
            <v-img
              v-if="allSizePhotoList[0]"
              class="cardProduct--image"
              :lazy-src="allSizePhotoList[0].middlePhotoUrl"
              :src="allSizePhotoList[0].originPhotoUrl"
              aspect-ratio="1"
              contain
            >       
              <template v-slot:placeholder>
                <v-row
                  v-if="!allSizePhotoList[0].originPhotoUrl"
                  eagle-list="photo-placeholder"
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-icon size="22">{{ placeholderIcon }}</v-icon>
                </v-row>
              </template>
            </v-img>
          </router-link>
        </div>
      </div>

      <!-- 手機版 -->
      <div v-if="$helper.isMobile()" class="d-flex justify-space-between cardProduct--list-container pa-2">
        <div class="flex-grow-0 flex-shrink-0" style="width: 40px">
          <v-checkbox
            v-if="batchConfig && tokenRole == 'provider'"
            @change="toggleSelected"
            v-model="selected"
            class="list-item-checkbox"
          ></v-checkbox>
        </div>
        <div class="flex-grow-1 flex-shrink-1 pr-4">
          
            <div class="cardProduct--title" style="height: 100%">
              <router-link class="cardProduct--title" :to="toProductDetail" style="display: block;width: 100%; height: 100%">
                {{ name }}
              </router-link>
            </div>
        </div>
        <div class="flex-grow-0 flex-shrink-0" style="width: 90px">
          <div>
            <v-switch
              dense
              color="green"
              hide-details
              inset
              :label="actionText"
              @change="action"
              v-model="status"
            ></v-switch>
          </div>

          <div class="pt-1">
            <v-btn small color="primary" :to="cloneProductRoute">
              複製商品
            </v-btn>
          </div>
        </div>
      </div>
    </div>


    <listDataAction
      v-if="false"
      :row="data"
      :listKey="listKey"
    ></listDataAction>

  </v-card>
</template>

<script>
import listItem from "@/components/liffList/listItem.js";
import listDataAction from "@/components/list/listDataAction.vue";
import favoriteBtn from "@/components/buttons/favoriteBtn";

export default {
  mixins: [listItem],
  components: {
    listDataAction,
    favoriteBtn,
  },
  data: () => ({
    status: false
  }),
  computed: {
    tokenRole() {
      return this.$store.getters['token/tokenRole']
    },
    providerId() {
      if(this.tokenRole == 'provider') return this.$store.getters['member/providerId']
      if(this.tokenRole == 'store') return this.$route.params.providerId
      return ''
    },
    storeId() {
      if(this.tokenRole == 'store') return this.$store.getters['member/storeId']
      return null
    },
    toProductDetail() {
      if (this.$route.name == "product-list")
        return { name: "product-update", params: { target: this.productId } };
      if (this.$route.name == "store-product-list")
        return {
          name: "store-product-detail",
          params: {
            providerId: this.providerId,
            productId: this.productId,
          },
        };
      return null;
    },
    placeholderIcon() {
      return "fa fa-image";
    },
    productId() {
      if (!this.data) return "";
      return this.data.id;
    },
    name() {
      if (!this.data) return "";
      return this.data.name;
    },
    calculate_unit() {
      if (!this.data) return "";
      return this.data.calculate_unit;
    },
    price() {
      if (!this.data) return "";
      return this.data.price;
    },
    daily_alert() {
      if (!this.data) return false;
      return this.data.daily_alert;
    },
    computedPrice() {
      if (!this.calculate_unit) return this.price;
      return `$${this.price}/${this.calculate_unit}`;
    },
    unit() {
      return this.calculate_unit
    },
    photoList() {
      if (!Array.isArray(this.data.photos)) {
        return [this.data.photos].filter(v => v)
      }
      return this.data.photos
    },
    allSizePhotoList() {
      return this.photoList.map((photo) => {
        if(!photo) return null
        photo.tinyPhotoUrl = this.$helper.getPhotoUrl(photo, ["tiny", "small"]) || ""
        photo.smallPhotoUrl = this.$helper.getPhotoUrl(photo, ["small", "tiny"]) || ""
        photo.middlePhotoUrl = this.$helper.getPhotoUrl(photo, ["middle", "origin"]) || ""
        photo.originPhotoUrl = this.$helper.getPhotoUrl(photo, ["origin", "middle"]) || ""
        return photo
      }).filter(v => v)
    },
    dataAction() {
      return this.$store.getters[`list/${this.listKey}/dataAction`];
    },
    meta() {
      return this.$store.getters[`list/${this.listKey}/meta`];
    },
    showFavoriteBtn() {
      return this.tokenRole == 'store'
    },
    favorites() {
      return this.meta.favorites
    },
    isFavorite() {
      if(!this.favorites) return false
      return this.meta.favorites.includes(this.productId)
    },
    priceEnable() {
      return !this.daily_alert
    },
    unitEnable() {
      return this.$store.getters['store/isCooperation'] && this.daily_alert
    },
    showAction() {
      return this.tokenRole == 'provider'
    },
    actionText() {
      if(this.tokenRole != 'provider') return ''
      if(this.status) return '上架'
      return '下架'
    },
    batchConfig() {
      return this.$store.getters[`list/${this.listKey}/batchConfig`]
    },
    actionColor() {
      if(this.status) return 'secondary'
      return 'primary'
    },
    shareProductRoute() {
      return {
        name: "product-share",
        query: {
          id: this.data.id,
          name: this.data.name
        }
      }
    },
    cloneProductRoute() {
      return {
        name: 'product-create',
        query: {
          clone: this.data.id
        }
      }
    },
    displayCardMode() {
      return this.$store.getters[`list/${this.listKey}/displayCardMode`]
    },
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler() {
        this.status = this.data.status
      }
    }
  },
  methods: {
    async action() {
      this.$apopup.base({
        title: '確定要切換商品狀態？',
        applyCallback: () => {
          this.toggleStatus()
        },
        closeCallback: (isCancel) => {
          if (isCancel) {
            this.status = !this.status
          }
        },
      })
    },
    async toggleStatus() {
      this.$store.dispatch('loading/active')
      try {
        console.log('this.status:', this.status)
        this.status ? this.$root.gaLogEvent('供應商_點擊_上架商品') : this.$root.gaLogEvent('供應商_點擊_下架商品')
        const target = this.productId
        const res = await this.$api.collection.productApi.patch(this.providerId, target, 'status', this.status)
      } catch(err) {
        console.error(err)
        this.$snotify.error('發生意外錯誤')
      } finally {
        this.$store.dispatch('loading/close')
      }
    },
  },
};
</script>

<style lang="sass">
.cardProduct
  position: relative
  padding: 6px
  &--list-container
    border: 1px solid #b3b3b3
    border-radius: 10px
  &--image
    border: 1px solid #ECECEC
    border-radius: 5px
  &--title
    font-size: 16px
    color: #333333 !important
    margin: 10px 0 8px !important
  &--price
    font-size: 18px
    font-weight: 550
    color: #006DE0
  .favoriteBtn
    position: absolute
    right: 1rem
    top: 1rem
    z-index: 1
.cardProduct
  .v-input--checkbox
    width: 30px
</style>